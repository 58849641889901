<template>
  <div class="mt-6">
    <n-form
      :label-placement="isMobile?'top':'left'"
      :label-align="'left'"
      label-width="auto"
    >
      <n-form-item :label="t('labels.title')">
        <n-input
          v-model:value="newConversationInfo.title"
          :placeholder="t('tips.pleaseNameATitle')"
        />
      </n-form-item>
      <!-- <n-form-item :label="t('labels.source')">
        <n-select v-model:value="newConversationInfo.source" :options="availableChatSourceTypes" />
      </n-form-item> -->
      <n-form-item :label="t('labels.model')">
        <n-select
          v-model:value="newConversationInfo.model"
          :options="availableModels"
          :virtual-scroll="false"
          :consistent-menu-width="false"
          :render-label="renderModelSelectionLabel"
          :render-option="renderModelSelectionOption"
        >
          <template #action>
            <div class="my-1 h-23 w-100 lt-sm:max-w-70 flex flex-col justify-between">
              <div class="text-xs text-right">
                {{ t('commons.remain') }}:
                {{
                  getCountTrans(
                    userStore.user?.setting[newConversationInfo.source!].per_model_ask_count[newConversationInfo.model!]
                  )
                }}
              </div>
            </div>
          </template>
        </n-select>
      </n-form-item>
      <!-- <n-form-item :label="t('commons.modelDescriptions')">
        <div class="text-sm text-inherit whitespace-pre-line">
          {{t(`modelDescriptions.${newConversationInfo.source}.${currentHoveringModel || newConversationInfo.model}`)}}
        </div>
      </n-form-item> -->
      <n-form-item v-if="newConversationInfo.model" :label="t('commons.modelDescriptions')">
        <div class="text-sm text-inherit whitespace-pre-line">
          {{ t(`modelDescriptions.${newConversationInfo.source}.${newConversationInfo.model}`) }}
        </div>
      </n-form-item>
    </n-form>
  </div>
</template>

<script setup lang="ts">
import { NTooltip, SelectOption } from 'naive-ui';
import { computed, h, ref, VNode, watch } from 'vue';

import { useBasicLayout } from '@/hooks/useBasicLayout';
import { i18n } from '@/i18n';
import { useAppStore, useUserStore } from '@/store';
import { NewConversationInfo } from '@/types/custom';
import { ChatSourceTypes } from '@/types/schema';
import { getCountTrans } from '@/utils/chat';

import NewConversationFormModelSelectionLabel from './NewConversationFormModelSelectionLabel.vue';

const t = i18n.global.t as any;

const userStore = useUserStore();
const appStore = useAppStore();
const { isMobile } = useBasicLayout();

const emits = defineEmits<{
  (e: 'input', newConversationInfo: NewConversationInfo): void;
}>();

const currentHoveringModel = ref<string | null>(null);

const availableChatSourceTypes = computed<SelectOption[]>(() => {
  if (!userStore.user) {
    return [];
  }
  return [
    {
      label: t('sources_short.openai_api'),
      value: 'openai_api',
      disabled: !userStore.user.setting.openai_api.allow_to_use,
    },
  ];
});

const availableModels = computed<SelectOption[]>(() => {
  if (!userStore.user) {
    return [];
  }
  return userStore.user.setting.openai_api.available_models.map((model) => ({
    label: t(`models.${model}`),
    value: model,
  }));
});

const newConversationInfo = ref<NewConversationInfo>({
  title: null,
  source: null,
  model: null,
});

function renderModelSelectionLabel(option: SelectOption) {
  return h(NewConversationFormModelSelectionLabel, {
    source: newConversationInfo.value.source!,
    model: option.value as string,
  });
}

function renderModelSelectionOption({ node, option }: { node: VNode; option: SelectOption }) {
  return h(
    NTooltip,
    {
      class: 'hidden',
      onUpdateShow: (value: boolean) => {
        // console.log('on-update:show', value);
        if (value) {
          currentHoveringModel.value = option.value as string;
        } else {
          currentHoveringModel.value = null;
        }
      },
    },
    {
      trigger: () => node,
      default: () => null,
    }
  );
}

function setDefaultValues() {
  //   const defaultSource = computed(() => {
  if (appStore.lastSelectedSource) {
    if (availableChatSourceTypes.value.find((source) => source.value === appStore.lastSelectedSource)) {
      newConversationInfo.value.source = appStore.lastSelectedSource;
    }
  } else {
    newConversationInfo.value.source =
      availableChatSourceTypes.value.length > 0 ? (availableChatSourceTypes.value[0].value as ChatSourceTypes) : null;
  }
}

setDefaultValues();

watch(
  () => {
    return {
      title: newConversationInfo.value.title,
      source: newConversationInfo.value.source,
      model: newConversationInfo.value.model,
    } as NewConversationInfo;
  },
  (newVal, _prev) => {
    // console.log('newConversationInfo', newVal);
    emits('input', newVal);
  },
  { immediate: true }
);

watch(
  () => newConversationInfo.value.source,
  () => {
    newConversationInfo.value.model = null;
  }
);
</script>
